import { useTranslation } from 'react-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { Result, Button } from 'antd'
import Link from 'next/link'

export default function Custom404 () {
  const { t } = useTranslation()
  return (
    <Result
      status='404'
      title='404'
      subTitle={t('common.text.page_not_found')}
      extra={<Button type='primary'><Link href='/dashboard'>{t('common.text.back_home')}</Link></Button>}
    />
  )
}

export const getStaticProps =
    async ({ locale }) => {
      return {
        props: {
          ...await serverSideTranslations(locale, ['common'])
        }
      }
    }
